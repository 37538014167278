import React from 'react';
import './OurServices.scss';
import babyintervention from '../../../Assets/ABA-Services/Early_Intervention_Plan.png';
import babywriting from '../../../Assets/ABA-Services/School_Age_Support_Plan.png';
import { Button } from 'primereact/button';

const OurServices = () => {
  return (
    <div className='our-section mt-8'>
      <div className='m-5'>
        <h1 className='heading flex align-items-center justify-content-center'>Our Services</h1>

        <div className='plan-sec'>
          <div className='plan-section'>
            <div className='img-sec'>
              <img src={babyintervention} alt='babyintervention' />
            </div>
            <div className='name'>Early Intervention Plan</div>
            <div className='content'>Targeting foundational skills for children aged 2-6, with intensive one-on-one therapy and parent training.</div>
            <div className='plan-button'>
              <Button className='services-buttton'>
                <a href="/abaServices/early_intervention_plan">Learn More</a>
              </Button>
            </div>
          </div>

          <div className='plan-section'>
            <div className='img-sec'>
              <img src={babywriting} alt='babywriting' />
            </div>
            <div className='name'>School-Age Support Plan</div>
            <div className='content'>Focused on enhancing academic, social, and adaptive behaviors for children aged 7-12, through individualized support, social skills groups, and collaboration with educational teams.</div>
            <div className='plan-button'>
              <Button className='services-buttton'>
                <a href="/abaServices/school_age_support_plan">
                  Learn More</a>
              </Button>
            </div>
          </div>
        </div>
        <p className='paragraph m-5'>Each child at our clinic receives personalized care designed to promote their unique strengths and abilities. Our team of highly qualified ABA therapists, BCBAs, and psychiatric professionals work in tandem to ensure that our approach not only addresses behavioral and educational goals but also supports the emotional and psychological well-being of each child and their family.</p>
      </div>
    </div>
  );
};

export default OurServices;
