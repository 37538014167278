import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Testimonials.scss';
import { Carousel } from 'primereact/carousel';
import quote from '../../Assets/Home Page/quote-left-solid.svg';
import yellowstar from '../../Assets/Home Page/Star_Icon_Yellow.svg';
import graystar from '../../Assets/Home Page/Star_Icon_Gray.svg';



const Testimonials = () => {

    const testimonials = [
        {
            name: 'John D',
            content: 'As a former employee of only being there 6 months it was such an amazing experience and opportunity to learning new things to becoming a teacher one day..',
        },
        {
            name: 'Sara P',
            content: 'We cannot thank the staff enough for the amazing care that they have provided our son.',
        },
        {
            name: 'Alex R',
            content: 'Autism Complete Care - as far as our experiences - is just fantastic! I have recommended them a few times, and will gladly do it again.',
        },
        {
            name: 'Sam K',
            content: 'Our daughter (turns 1 tomorrow) has been going here since she was 4 months old. The teachers and the staff are amazing.',
        },
    ];

    const testimonialsTemplate = (testimonials) => {
        return (
            <div className="testimonial-card">
                <div className='testimonial'>
                    <div className='tuser-name'>{testimonials.name}</div>
                    <div><img className='quote-image' src={quote} alt="" /></div>
                </div>
                <div className='tuser-desc'>
                    {testimonials.content}
                </div>
                <div className='rating'>
                    <div><img className='star-image' src={yellowstar} alt="" /></div>
                    <div><img className='star-image' src={yellowstar} alt="" /></div>
                    <div><img className='star-image' src={yellowstar} alt="" /></div>
                    <div><img className='star-image' src={yellowstar} alt="" /></div>
                    <div><img className='star-image' src={graystar} alt="" /></div>
                </div>
            </div>
        );
    };

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    return (
        <>
            <div className='testmonials'>
                <div className="testmonials-heading">Testimonials</div>
                <div className='grid'>
                    <div className='col md:col-12 lg:col-4 tleft-div'>
                        <div className='percentage'>
                            <div className='per-num'>96%</div>
                            <div className='per-desc'>of our families would highly recommend us</div>
                        </div>
                        <div className='hear'>
                            Hear From Parents Like You
                        </div>
                    </div>


                    <div className='col md:col-12 lg:col-8 tright-div'>
                        <Carousel value={testimonials}
                            numVisible={2}
                            numScroll={1}
                            responsiveOptions={responsiveOptions}
                            className="custom-carousel"
                            circular
                            itemTemplate={testimonialsTemplate} />
                    </div>
                </div>
            </div>



        </>

    )
}

export default Testimonials;