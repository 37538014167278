import React, { useEffect, useState } from 'react'
import './Services.scss';
import OnsiteImage from '../../../Assets/Parentspage/On-Site_Psychiatric_Expertise.png';
import TherapyImage from '../../../Assets/Parentspage/Personalized_ABA_Therapy.png';
import FamilyImage from '../../../Assets/Parentspage/Family_Support_And_Involvement.png';
import SupportiveImage from '../../../Assets/Parentspage/A_Supportive_Enviroment.png';

const System = () => {
    
    const [showContent, setShowContent] = useState(false);
    const [hoveredItem, setHoveredItem] = useState("assess");

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem("assess");
    };

    useEffect(() => {
        const hash = window.location.hash;

        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
            
        } else {
            setShowContent(false);
        }
    }, [window.location.hash]);

    return (
        <>
        <div className="header-container">
        <h1>What We Offer</h1>
    </div>
                        <div className="system-banner-section">
                            <div className={`item ${hoveredItem === "assess" ? 'hovered' : ''}`} data-order="1" onMouseEnter={() => handleMouseEnter("assess")} onMouseLeave={handleMouseLeave}>

                                {hoveredItem === "assess" ? (
                                    <div className="content">
                                        <h2>Psychiatric Consulting Services</h2>
                                        <p>We incorporate psychiatric consultations and interventions, ensuring a holistic approach to your child’s development and well-being.</p>
                                    </div>
                                ) :
                                    <div className="default" style={{ backgroundColor: "#5E8600CC", height: "100%" }}>
                                        <h2 className='default-label'>Psychiatric Consulting Services</h2>
                                    </div>
                                }
                            </div>

                            <div className={`item ${hoveredItem === "design" ? 'hovered' : ''}`} data-order="2" onMouseEnter={() => handleMouseEnter("design")} onMouseLeave={handleMouseLeave}>
                                {hoveredItem === "design" ? (
                                    <div className="content">
                                        <h2>Personalized ABA Therapy</h2>
                                        <p>Our board-certified behavior analysts (BCBAs) and highly trained therapists work closely with you to develop a customized therapy plan that evolves with your child's progress.</p>
                                    </div>
                                ) :
                                    <div className="default" style={{ backgroundColor: "#1F4999CC", height: "100%" }}>
                                        <h2 className='default-label'>Personalized ABA Therapy</h2>
                                    </div>
                                }
                            </div>

                            <div className={`item ${hoveredItem === "enable" ? 'hovered' : ''}`} data-order="3" onMouseEnter={() => handleMouseEnter("enable")} onMouseLeave={handleMouseLeave} >
                                {hoveredItem === "enable" ? (
                                    <div className="content">

                                        <h2>Family Support and Involvement</h2>
                                        <p>Our board-certified behavior analysts (BCBAs) and highly trained therapists work closely with you to develop a customized therapy plan that evolves with your child's progress.</p>
                                    </div>
                                ) :
                                    <div className="default" style={{ backgroundColor: "#F1852DCC", height: "100%" }}>
                                        <h2 className='default-label'>Family Support and Involvement</h2>
                                    </div>
                                }
                            </div>

                            <div className={`item ${hoveredItem === "perform" ? 'hovered' : ''}`} data-order="4" onMouseEnter={() => handleMouseEnter("perform")} onMouseLeave={handleMouseLeave} >
                                {hoveredItem === "perform" ? (
                                    <div className="content">

                            <h2>A Supportive Environment</h2>
                            <p>Our clinic is designed to be a safe and nurturing space where your child can learn, grow, and explore at their own pace.</p>
                        </div>
                    ) :
                        <div className="default" style={{ backgroundColor: "#B84593CC", height: "100%" }}>
                            <h2 className='default-label'>A Supportive Environment</h2>
                        </div>
                    }
                </div>
            </div>


            <div className="mob-system-banner-section">
                <div className="content">
                    <div className="overlay" style={{background : "#5e8600cc"}}>
                        <h2>Psychiatric Consulting Services</h2>
                        <p>We incorporate psychiatric consultations and interventions, ensuring a holistic approach to your child’s development and well-being.</p>
                    </div>
                    <img src={OnsiteImage} alt="OnsiteImage" className="image" />
                </div>
                <div className="content">
                    <div className="overlay" style={{background:"#1f4999cc"}}>
                        <h2>Personalized ABA Therapy</h2>
                        <p>Our board-certified behavior analysts (BCBAs) and highly trained therapists work closely with you to develop a customized therapy plan that evolves with your child's progress.</p>
                    </div>
                    <img src={TherapyImage} alt="TherapyImage" className="image" />
                </div>
                <div className="content">
                    <div className="overlay" style={{background : "#f1852dcc"}}>
                        <h2>Family Support and Involvement</h2>
                        <p>Our board-certified behavior analysts (BCBAs) and highly trained therapists work closely with you to develop a customized therapy plan that evolves with your child's progress.</p>
                    </div>
                    <img src={FamilyImage} alt="FamilyImage" className="image" />
                </div>
                <div className="content">
                    <div className="overlay" style={{background : "#b84593cc"}}>
                        <h2>A Supportive Environment</h2>
                        <p>Our clinic is designed to be a safe and nurturing space where your child can learn, grow, and explore at their own pace.</p>
                    </div>
                    <img src={SupportiveImage} alt="SupportiveImage" className="image" />
                </div>
            </div>

        </>



    )
}

        
    
                            
        

export default System;