import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import './Ourstory.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const OurStory = () => {
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleFullText = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };

  return (
    <div className="our-story-container">
      <h2 className="our-story-title">Our Story</h2>
      <div className="our-story-text">
        <p>
          Autism Complete Care started with a team of experts in behavior analysis, psychiatry, and technologists who wanted to change how autism therapy is done. They saw that every child is special and that therapy should be made just for them. They made a clinic that understands each child’s needs, includes expert mental health advice, and works closely with families in the therapy journey.
        </p>
        {/* This button will only be visible in mobile view before the additional text */}
        {isMobile && !isFullTextVisible && (
          <Button 
            label="View More" 
            onClick={toggleFullText} 
            className="p-button-outlined our-story-button" 
          />
        )}
        {/* The additional text section, toggled by the button in mobile view */}
        {(isMobile ? isFullTextVisible : true) && (
          <div className="additional-text shown">
            <p>
              We began in a small, friendly place, but with a big dedication to our values and the families we help. Our story is about caring deeply, never giving up, and believing in the bright future of every child with autism.
            </p>
            {/* This button will only be visible in mobile view after the additional text */}
            {isMobile && (
              <Button 
                label="View Less" 
                onClick={toggleFullText} 
                className="p-button-outlined our-story-button" 
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OurStory;
