import React from 'react';
import { Button } from 'primereact/button';
import './GetInTouch.scss';
import backgroundImg from '../../../Assets/Parentspage/Get_in_touch.png'; 
import { useNavigate } from 'react-router-dom';

const GetInTouch = () => {

  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/getstarted")
  }

  return (
    <div className="get-in-touch-container" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="get-in-touch-content">
        <div className="text-section">
          <h2>Get in Touch</h2>
          <p>To learn more about our services or to schedule a visit, please get in touch with us. We look forward to meeting you and discussing how we can support your child’s journey towards growth and happiness.</p>
        </div>
        <div className="action-section">
          <Button className="p-button-outlined get-started-button" onClick={handleGetStarted}>Schedule Call</Button>
          <div className="phone-number">
            <span>(844) 436-2792</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
