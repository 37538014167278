import React from 'react';
import './Ourteam.scss';

const TeamSection = () => {
  return (
    <>
      <div className="team-section">
        <h2 className="team-header">Our Team</h2>
        <p className="team-description">Our team is comprised of highly skilled and compassionate professionals, including</p>
        <div className="team-content">
          <div className="team-points">
            <h3 className="point-title psychiatrists">Consulting Psychiatrists: Specialists</h3>
            <p className="point-description">Who provide psychiatric evaluations and treatment, ensuring a comprehensive approach to each child’s well-being.</p>

            <h3 className="point-title bcbas">Board-Certified Behavior Analysts (BCBAs)</h3>
            <p className="point-description">Experts in designing and implementing personalized ABA therapy plans.</p>

            <h3 className="point-title rbts">Registered Behavior Technicians (RBTs)</h3>
            <p className="point-description">Trained therapists who work directly with children, guided by BCBA-developed plans.</p>

            <h3 className="point-title support-staff">Support Staff</h3>
            <p className="point-description">Dedicated individuals who create a welcoming, supportive environment for our families and assist with the clinic's day-to-day operations.</p>
          </div>
        </div>
      </div>
      <div className="team-section-footer">
        <p className="team-footer">
          Together, we are committed to continuous learning and development, ensuring our team remains
          at the forefront of ABA therapy and psychiatric care.
        </p>
      </div>
    </>
  );
};

export default TeamSection;
