import React from 'react';
import Header from '../Home/Header/Header';
import Footer from '../Home/Footer/Footer';
import School_Age_Support_Plan_Img from "../../Assets/ABA-Services/School_Age_Support_Plan.png";
import { Button } from 'primereact/button';
import { FaChevronLeft } from "react-icons/fa";

const School_Age_Support_Plan = () => {
    return (
        <>
            <Header />

            <div className='p-4 aba-section' style={{ marginTop: "120px" }}>

                <Button className='back-button'><a href="/abaServices"> <FaChevronLeft /> Back</a></Button>

                <div className="aba-early-section w-full school-plan-section">
                    <div className=' p-0'>
                        <h2>School-Age Support Plan</h2>

                        <div className='abc-objective'>
                            <h3>Objective:</h3>
                            <p>
                                Designed for children aged <b>7-12 years</b>, his plan focuses on enhancing
                                academic skills, social nuances, and adaptive behaviors that
                                support success in school and community settings.
                            </p>
                        </div>

                    </div>
                    <div className='early-plan-image p-0'>
                        <img src={School_Age_Support_Plan_Img} alt="" />
                    </div>
                </div>
                <div className="acc-key-points">
                    <h3>Key Components:</h3>
                    <ul>
                        <div className='point-one'>
                            <li>
                                <div >
                                    <b >Functional Behavior Assessment (FBA): </b> Conduct assessments to understand the context of challenging behaviors within school and social settings.
                                </div>
                            </li>
                        </div>
                        <div className='point-two'>
                            <li>
                                <div>
                                    <b>Individualized Support:</b> Develop a customized plan that includes a mix of one-on-one therapy, group sessions, and in-school support to promote academic and social success.
                                </div>
                            </li>
                        </div>
                        <div className='point-three'>

                            <li>
                                <div>
                                    <b>Social Skills Group:</b>  Organize weekly group sessions focused on building social understanding, conversation skills, and friendship development.
                                </div>
                            </li>
                        </div>
                        <div className='point-four'>
                            <li>
                                <div>
                                    <b>Coordination with Educational Teams:</b> Work closely with school personnel to ensure strategies are consistent across settings and to advocate for appropriate accommodations.

                                </div>
                            </li>

                        </div>
                    </ul>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default School_Age_Support_Plan