import React from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import ACCBanner from './HomeBanner/HomeBanner'
import WhyUs from './Why-Us/Why-Us'
import Insurance from './Insurance/Insurance'
import ThreeEasySteps from './3EasySteps/3EasySteps'
import Testimonials from '../../Shared-Components/Testimonials/Testimonials'


const Home = () => {
    return (
        <div>
            <Header />
            <ACCBanner />
            <WhyUs />
            <ThreeEasySteps />
            <Insurance />
            <Testimonials />
            <Footer />
        </div>
    )
}

export default Home