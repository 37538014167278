import React from "react";
import "./3EasySteps.scss";
import step1 from "../../../Assets/Home Page/Autism_Testing_Steps1.png";
import step2 from "../../../Assets/Home Page/Psychiatric_Evaluation_Steps.png";
import step3 from "../../../Assets/Home Page/ABA_Therapy_Steps.png";
import dots from "../../../Assets/Home Page/Dots.svg";

const ThreeEasySteps = () => {
  return (
    <>
      <div className="three-easy-steps">
        <div className="steps-banner">
          Enrolling your child at Autism Complete Care is a simple, 3-step
          process designed to provide the best care for their needs
        </div>
        <div className="easy">3 Easy Steps</div>

        <div className="steps">
          <div className="left-div">
            <img className="steps-img" src={step1} alt="" />
          </div>
          <div className="middle-div">
            <img src={dots} alt="" />
          </div>
          <div className="right-div">
            <div className="steps-content" style={{ border: "2px solid #F1852D" }}>
              <div className="steps-title" style={{ color: "#F1852D" }}>Autism Testing</div>
              <div className="steps-desc">
                Begin with a comprehensive autism assessment to understand your
                child's unique needs. Our team uses the latest tools to ensure
                an accurate understanding of your child's developmental profile.
              </div>
            </div>
          </div>
        </div>


        <div className="second-steps">
          <div className="second-left-div">
            <div className="steps-content" style={{ border: "2px solid #97C230" }} >
              <div className="steps-title" style={{ color: "#97C230" }}>Psychiatric Evaluation</div>
              <div className="steps-desc">
                Following the assessment, your child will undergo a psychiatric
                evaluation by our experienced on-site consulting psychiatrist. This step
                helps in tailoring a care plan that best supports your child's
                emotional and psychological well-being.
              </div>
            </div>
          </div>
          <div className="middle-div">
            <img src={dots} alt="" />
          </div>
          <div className="second-right-div">
            <img className="steps-img" src={step2} alt="" />
          </div>
        </div>

        <div className="steps">
          <div className="left-div">
            <img className="steps-img" src={step3} alt="" />
          </div>
          <div className="middle-div">
            <img src={dots} alt="" />
          </div>
          <div className="right-div">
            <div className="steps-content" style={{ border: "2px solid #1F4999" }} >
              <div className="steps-title" style={{ color: "#1F4999" }}>ABA Therapy</div>
              <div className="steps-desc">
                Once we have a complete understanding of your child's needs,
                we'll commence personalized ABA therapy. Our expert therapists
                will work closely with your child to develop essential skills
                and abilities, ensuring a nurturing and supportive learning
                environment.
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ThreeEasySteps;
