import React from 'react';
import { Button } from 'primereact/button';
import './CommunityBanner.scss'; 

const CommunityBanner = () => {
  return (
    <div className="community-section">
      <h1>Join Our Community</h1>
      <p>
        We invite you to join the Autism Complete Care family, where we celebrate each child’s individuality 
        and work together to unlock their full potential. Whether you are new to ABA therapy or looking for 
        a more personalized approach, we are here to support you and your child every step of the way.
      </p>
      <Button label="Join Now" className="p-button-raised p-button-warning join-now-button" />
    </div>
  );
};

export default CommunityBanner;
