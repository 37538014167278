import React from "react";
import "./Why-Us.scss";
import medicalhouse from '../../../Assets/Home Page/house-medical-regular.svg';
import userdoctor from '../../../Assets/Home Page/user-doctor-regular.svg';
import heart from '../../../Assets/Home Page/heart-regular.svg';

const WhyUs = () => {
    return (
        <>
            <div className="why-us">
                <div className="whyus-header-section">
                    <div className="why-us-header">Why Us?</div>
                    <div className="why-us-subtext">"Autism Complete Care" isn't just our name—it's our promise. Here's what sets us apart</div>
                </div>
                <div className="whyus-content grid">
                    <div class="field col-12 md:col-12 lg:col-4">
                        <div className="whyus-items text-center">
                            <div className='whyus-card-header'>
                                <div className='whyus-icon'>
                                    <img className='w-icon' src={medicalhouse} alt="" />
                                </div>
                                <div className='whyus-title'>Tailored ABA Therapy Excellence</div>
                            </div>
                            <div className="whyus-desc">Our dedicated team employs state-of-the-art ABA techniques, ensuring therapy that's as unique as your child.
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 md:col-12 lg:col-4">
                        <div className="whyus-items text-center">
                            <div className='whyus-card-header'>
                                <div className='whyus-icon'>
                                    <img className='w-icon' src={userdoctor} alt="" />
                                </div>
                                <div className='whyus-title'>Psychiatric Consulting Services</div>
                            </div>
                            <div className="whyus-desc">Our experienced child consulting psychiatrist brings a deeper understanding and an enriched perspective to every treatment plan.
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 md:col-12 lg:col-4">
                        <div className="whyus-items text-center">
                            <div className='whyus-card-header'>
                                <div className='whyus-icon'>
                                    <img className='w-icon' src={heart} alt="" />
                                </div>
                                <div className='whyus-title'> Individualized Care for Every Child</div>
                            </div>
                            <div className="whyus-desc">At Autism Complete Care, every child's journey is personalized. We celebrate and nurture the individuality of each child, fostering growth, learning, and joy.
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default WhyUs;
