import React from 'react';
import './Footer.scss';
import { Divider } from "primereact/divider";
import acclogo from "../../../Assets/Home Page/Logo_ACC_White.png";



const Footer = () => {
    return (
        <>
            <div className="acc-footer-section grid">
                <div className="footer-logo field col-12 md:col-12 lg:col-3">
                    <img src={acclogo} alt="Logo" />
                    <div className="logo-desc">
                        A Partner in Your Child’s Journey
                    </div>
                </div>
                <div className="quick-links field col-12 md:col-12 lg:col-3">
                    <h4>Quick Links:</h4>
                    <Divider />
                    <ul>
                        <li> <a href="/abaServices">ABA Services</a> </li>
                        <li> <a href="/parents">Parents</a> </li>
                        {/* <li> <a href="/">Insurnace</a> </li> */}
                        {/* <li> <a >Resources</a> </li> */}
                        <li> <a href="/aboutus">About Us</a> </li>
                    </ul>
                </div>
                <div className="legals field col-12 md:col-12 lg:col-3">
                    <h4>Legals:</h4>
                    <Divider />
                    <ul>
                        <li>
                            <a href="https://staticfile.autismcompletecare.com/Terms+and+Conditions+for+Autism+Complete+Care.pdf" target="_blank" rel="noopener noreferrer" >Terms and conditions</a>
                        </li>
                        <li>
                            <a href="https://staticfile.autismcompletecare.com/Privacy+Policy+for+Autism+Complete+Care.pdf" target="_blank" rel="noopener noreferrer" >Privacy Policy</a>
                        </li>
                    </ul>
                </div>
                <div className="social-media field col-12 md:col-12 lg:col-3">
                    <h4>Contact Us:</h4>
                    <Divider />
                    <div className='mb-3'>Phone Number: (817) 763-5665</div>
                    <div>Email: info@autismcompletecare.com</div>
                    <h4>Connect with us:</h4>
                    <a href="https://www.facebook.com/profile.php?id=61559921365144" target="_blank" rel="noopener noreferrer">
                        <i className="pi pi-facebook mr-3" style={{ fontSize: "1.5rem" }}></i>
                    </a>
                    <a href="https://www.instagram.com/autismcompletecare" target='_blank' rel="noopener noreferrer">
                        <i className="pi pi-instagram mr-3" style={{ fontSize: "1.5rem" }}></i>
                    </a>
                    <a href="https://www.linkedin.com/company/autism-complete-care/" target="_blank" rel="noopener noreferrer">
                        <i className="pi pi-linkedin mr-3" style={{ fontSize: "1.5rem" }}></i>
                    </a>
                </div>
            </div>
        </>
    );
};

export default Footer;
