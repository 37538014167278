import React from 'react';
import Header from '../Home/Header/Header';
import ACCServices from './ACC-Services/ACCServices';
import OurServices from './Our-Services/OurServices';
import Footer from '../Home/Footer/Footer';


const ABAServices = () => {
    return (
        <>
            <Header />
            <ACCServices />
            <OurServices />
            <Footer />
        </>
    )
}

export default ABAServices;