import React from 'react';
import './ParentsBanner.scss';
import familyImage from './../../Assets/Parentspage/Family_Banner_Parents.png'; // The path to your image file

const BannerComponent = () => {
  return (
    <>
      <header className="mainHeader">
        <div className="mainHeader"><h1>Welcome to Autism Complete Care:</h1></div>
        <div className="mainHeader1"><h2>A Partner in Your Child’s Journey</h2></div>
      </header>
      <div className="autismCareContainer">
        <div className="imageSection mobileImage">
          <img src={familyImage} alt="Family" />
        </div>
        <div className="content">
          <div className="philosophySection">
            <div className="philosophyBox">
              <span className="philosophyHeader">Our Philosophy</span>
              <div className="philosophySubText">
                Individualized Complete Care for Every Child
              </div>
            </div>
            <div className="philosophyContent">
              At Autism Complete Care, we understand that every child is unique, with their strengths, challenges, and path to growth. We aim to walk alongside families like yours, providing personalized ABA therapy that nurtures your child's development in a caring, understanding, and joyful environment.
            </div>
          </div>
          <div className="approachSection">
            <div className="approachHeader">
              Our Approach
            </div>
            <div className="approachContent">
              Is rooted in the belief that effective therapy is tailored to each child. We integrate state-of-the-art ABA techniques with psychiatric expertise to create a comprehensive treatment plan that addresses your child's well-being.
            </div>
          </div>
        </div>
        <div className="imageSection desktopImage">
          <img src={familyImage} alt="Family" />
        </div>
      </div>
    </>
  );
};

export default BannerComponent;
