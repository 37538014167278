import React from 'react'
import Header from '../Home/Header/Header'
import System from './Services/Services'
import BannerComponent from './ParentsBanner'
import CommitmentSection from './Commitments/CommintmentSection'
import CommunityBanner from './Ourcommunity/CommunityBanner'
import GetInTouch from './GetInTouch/GetInTouch'
import Testimonials from '../../Shared-Components/Testimonials/Testimonials'
import Footer from '../Home/Footer/Footer'


const Parents = () => {
    return (
        <>
        <Header />
        <BannerComponent/>
        <System/>
        <CommitmentSection/>
        <CommunityBanner/>
        <Testimonials/>
        <GetInTouch/>
         <Footer/>
        </>
    )
}

export default Parents