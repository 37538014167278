import React, { useState, useEffect } from 'react'
import '../GetStarted-Form/GetStartedForm.scss';
import Contactus from '../../Assets/Get-Started/Contact_Us_Image.png';
// import logo from '../../Assets/Get-Started/logo.png';
import { ScrollTop } from 'primereact/scrolltop';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
// import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { Contact_Us, CONTENT_HEADERS } from '../../Utils/LandingService';
import CalendlyComponent from '../../Shared-Components/Calendly/Calendly.jsx';
import { Dialog } from 'primereact/dialog';
import Header from '../Home/Header/Header';
import Footer from '../Home/Footer/Footer';

const GetStartedForm = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        phoneNumberType: '',
        category: '',
        description: '',
        starTtime: '',
        endTime: '',
        selectedDays: [],
        termsChecked: false,
    });

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const onSubmit = () => {
        // console.log(formData);
        // const clientFormData = {
        //     name: formData.firstName,
        //     lastName: formData.lastName,
        //     email: formData.email,
        //     phoneNumber: formData.phoneNumber,
        //     type: formData.phoneNumberType,
        //     category: formData.category,
        //     description: formData.description,
        //     contactHours: formData.starTtime + '-' + formData.endTime,
        //     daysOfWeek: formData.selectedDays,
        // };

        // axios
        //     .post(Contact_Us, clientFormData, { headers: CONTENT_HEADERS })
        //     .then(() => {
        //         navigate('/');
        //     })
        //     .catch((error) => {
        //         console.error('API Error:', error);
        //     });
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //         termsChecked: e.checked,
    //     });
    // }

    const [visible, setVisible] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            Request Info
        </div>
    );

    const footerContent = (
        <div>
            <Button type="submit" label="Send me more info" severity="primary" onClick={onSubmit} />
        </div>
    );

    return (
        <>
            <ScrollTop />
            <Header />
            <div className='contact-us'>
                <div className="contact-section">
                    <div className="contact-left-section col-6">
                        <img className='contactus-img' src={Contactus} />
                        <div className='info'>
                            <div className='cu-sub-text'>Start Your Personalized ABA Therapy Journey Today. Our team is ready to provide the support your family needs.</div>
                            {/* <div className='ceo'>Startup2Launch CEO</div> */}
                            <div className='contact-info'>
                                {/* <div className='phone-info'>
                                    <div className='phone-text'>Call Us</div>
                                    <div className='ph-no'>(817) 763-5665</div>
                                </div>
                                <div className='or'>Or</div> */}
                                <div>
                                    <div className='socials'>Follow Us on</div>
                                    <ul className='socialicons-list'>
                                        <a href="https://www.instagram.com/autismcompletecare" target='_blank' rel="noopener noreferrer">
                                            <i className="pi pi-instagram mr-3" style={{ color: "white", fontSize: "1.5rem" }}></i>
                                        </a>
                                        <a href="https://www.facebook.com/profile.php?id=61559921365144" target="_blank" rel="noopener noreferrer">
                                            <i className="pi pi-facebook mr-3" style={{ color: "white", fontSize: "1.5rem" }}></i>
                                        </a>
                                        <a href="https://www.linkedin.com/company/autism-complete-care/" target="_blank" rel="noopener noreferrer">
                                            <i className="pi pi-linkedin mr-3" style={{ color: "white", fontSize: "1.5rem" }}></i>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='left-content'>
                        <div className="contact-right-section">
                            <div className='border-left'>
                                <div className='text1'>
                                    <b>Experienced and Compassionate Team: </b>
                                    Your child will be supported by BCBAs with over 20 years of
                                    experience and caring Registered Behavior Technicians (RBTs)
                                    who are dedicated to your child’s progress.
                                </div>
                            </div>
                            <div className='border-left'>
                                <div className='text1'>
                                    <b>Comprehensive, Multidisciplinary Care: </b>
                                    Unlike other ABA clinics, we have a consulting psychiatrist
                                    as part of the care team, ensuring a holistic approach
                                    that addresses both behavioral and medical aspects of your child's needs.
                                </div>
                            </div>

                            {/* <div className='text2'>Then reach out we'd love to hear about your project and provide help.</div> */}
                            <div className='contactus-buttons'>
                                <div className='calendly-component'>
                                    <CalendlyComponent />
                                </div>
                                {/* <div>
                                <Button className='ask-button' label="Ask for information" onClick={() => setVisible(true)} severity="help" outlined />
                            </div> */}
                            </div>
                        </div>
                    </div>

                    <Dialog visible={visible} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisible(false)}>
                        <div className="client-form">
                            <div className="grid">
                                <div className="field col-12 md:col-6 flex flex-column gap-2">
                                    <label htmlFor="firstName">First Name</label>
                                    <InputText id="firstName" name="firstName" value={formData.firstName} onChange={onInputChange} />
                                </div>
                                <div className="field col-12 md:col-6 flex flex-column gap-2">
                                    <label htmlFor="lastName">Last Name</label>
                                    <InputText id="lastName" name="lastName" value={formData.lastName} onChange={onInputChange} />
                                </div>

                                <div className="field col-12 flex flex-column gap-2">
                                    <label htmlFor="email">Email</label>
                                    <InputText id="email" name="email" value={formData.email} onChange={onInputChange} />
                                </div>
                                <div className="field col-12 flex flex-column gap-2">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <InputMask
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        mask="(999) 999-9999"
                                        value={formData.phoneNumber}
                                        onChange={onInputChange}
                                    />
                                </div>
                                {/* <div className="field col-12 md:col-6 flex flex-column gap-2">
                                        <label htmlFor="phoneNumberType">Phone Number Type</label>
                                        <Dropdown
                                            id="phoneNumberType"
                                            name="phoneNumberType"
                                            options={phoneNumberTypes}
                                            value={formData.phoneNumberType}
                                            onChange={onInputChange}
                                            placeholder="Select a type"
                                        />
                                    </div> */}
                                {/* <div className="field col-12 flex flex-column gap-2">
                                        <label htmlFor="description">Description</label>
                                        <InputTextarea id="description" name="description" rows={6} value={formData.description} onChange={onInputChange} />
                                    </div> */}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default GetStartedForm