import React from 'react';
import './Aboutusbanner.scss'; // Make sure the path matches your file structure
import bannerImage from '../../../Assets/Aboutus Page/About_Us_Header.png'; // Adjust the path to where your assets are located

const Aboutusbanner = () => {
  return (
    <div className="banner-container">
      <img
        src={bannerImage} 
        alt="Banner"
        className="banner-image"
      />
    </div>
  );
};

export default Aboutusbanner;
