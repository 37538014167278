import React  from "react";
import "./Insurance.scss";
import aetna from "../../../Assets/Home Page/Insurance/Aetna_logo.svg";
import cigna from "../../../Assets/Home Page/Insurance/Cigna-Logo.svg";
import tricare from "../../../Assets/Home Page/Insurance/US-TRICARE-Logo 1.svg";
import unitedhealthcare from "../../../Assets/Home Page/Insurance/unitedhealthcare-logo.svg";
import humana from "../../../Assets/Home Page/Insurance/Humana-Logo.svg";
import optum from "../../../Assets/Home Page/Insurance/optum-seeklogo-logo.svg";
import umr from "../../../Assets/Home Page/Insurance/UMR-Logo.svg";
import Slider from "react-slick";

const Insurance = () => {
  const images = [
    {
      image: aetna,
    },
    {
      image: cigna,
    },
    {
      image: tricare,
    },
    {
      image: unitedhealthcare,
    },
    {
      image: humana,
    },
    {
      image: optum,
    },
    {
      image: umr,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="insurance">
        <div className="insurance-title">Insurance Accepted</div>
        <Slider {...settings}>
            {images.map((image) => (
              <img className="insurance-images" src={image.image} alt="" />
            ))}
        </Slider>
      </div>
    </>
  );
};

export default Insurance;
