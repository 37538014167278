import React from 'react';
import './Missionvision.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const MissionVision = () => {
  return (
    <div className="mission-vision-container">
      <div className="mission-section">
        <div className="title mission">OUR MISSION</div>
        <div className="content blue-border-box">
          Empower autistic children to achieve their fullest potential through personalized, evidence-based ABA therapy. We are dedicated to fostering an environment of growth, learning, and joy, where each child’s individuality is celebrated and nurtured.
        </div>
      </div>
      <div className="vision-section">
        <div className="title1 vision">OUR VISION</div>
        <div className="content red-outline-box">
          We envision a world where all children on the autism spectrum receive the support, respect, and opportunities they deserve. To be a beacon of hope and a source of support for families, setting the standard for excellence in autism care through innovative therapies, Psychiatric consultation, compassionate support, and unwavering commitment to the children we serve.
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
