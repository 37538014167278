import React, { useState } from "react";
import "./Header.scss";
import logoOpen from "../../../Assets/Home Page/Logo_ACC_Blue.png";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleRedirect = () => {
        navigate('/getstarted');
    }

    return (
        <>
            <header className="acc-header">
                <nav className="navigation">
                    <div className="logo">
                        <a href="/"><img className="acc-logo" src={logoOpen} alt="" /></a>
                    </div>

                    <div
                        className={`menu-toggle ${showMenu ? "active" : ""}`}
                        onClick={toggleMenu}>
                        <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                        <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                        <div className={`bar ${showMenu ? "cross" : ""}`} ></div>
                    </div>

                    <div className={`menu ${showMenu ? "show-menu" : ""}`}>
                        <ul>
                            {/* <li className={location.pathname === '/' ? 'active-menu-item' : ''}>
                                <a href="/">Home</a>
                            </li> */}
                            <li className={`menu-item ${location.pathname === '/abaServices' ? 'active-menu-item service' : ''}`}>
                                <a href="/abaServices">ABA Services</a>
                            </li>
                            <li className={`menu-item ${location.pathname === '/parents' ? 'active-menu-item parent' : ''}`}>
                                <a href="/parents">Parents</a>
                            </li>
                            {/* <li className={location.pathname === '/insurance' ? 'active-menu-item' : ''}>
                                <a href="/">Insurance</a>
                            </li> */}
                            {/* <li className={location.pathname === '/resources' ? 'active-menu-item' : ''}>
                                <a>Resources</a>
                            </li> */}
                            <li className={`menu-item ${location.pathname === '/aboutus' ? 'active-menu-item about' : ''}`}>
                                <a href="/aboutus">About Us</a>
                            </li>
                            <li>
                                <Button onClick={handleRedirect}>Schedule Call</Button>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Header;
