import React from 'react';
import "./ACCServices.scss";
import baby from "../../../Assets/ABA-Services/ABA_Woman_helping_little_girl_image_1.png";
import paintingbaby from "../../../Assets/ABA-Services/ABA_kid_doing_a_heart_with_hands_image_2.png";
import joybaby from "../../../Assets/ABA-Services/ABA_Woman_helping_little_boy_image_3.png";

const ACCServices = () => {
  return (
    <div className='acc-section'>
      <div className='m-5'>
        <h1 className='heading flex align-items-center justify-content-center'>ABA Services</h1>
        <p className='paragraph   m-5'>At "Autism Complete Care," we are dedicated to offering a comprehensive suite of ABA (Applied Behavior Analysis) therapy services tailored to meet the individual needs of children with autism
          across all age groups. Our clinic is a nurturing environment where early intervention, school-age support, and adolescent transition planning are at the heart of our mission to foster growth, learning, and independence.</p>
        <div className="acc-image flex align-items-center justify-content-around flex-wrap column-gap-3 row-gap-6 mt-5 mb-5">
          <img src={baby} alt="baby" className='flex align-items-center justify-content-center' />
          <img src={paintingbaby} alt="paintingbaby" className='flex align-items-center justify-content-center' />
          <img src={joybaby} alt="joybaby" className='flex align-items-center justify-content-center' />
        </div>
      </div>
    </div>
  );
};

export default ACCServices;
