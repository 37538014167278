import React, { useEffect, useState } from 'react';
import './HomeBanner.scss';
import { Button } from 'primereact/button';
import Bannerimage1 from '../../../Assets/Home Page/HomeBanner1.png';
import Bannerimage2 from '../../../Assets/Home Page/HomeBanner2.png';
import Bannerimage3 from '../../../Assets/Home Page/HomeBanner3.png';
import { useNavigate } from 'react-router-dom';

const bannerData = [
    {
        image: Bannerimage1,
        headingline1: 'Nurturing Minds,',
        headingline2: 'Shaping Futures',
    },
    {
        image: Bannerimage2,
        headingline1: 'Compassionate Care,',
        headingline2: 'Exceptional Results',
    },
    {
        image: Bannerimage3,
        headingline1: 'Transforming Autism',
        headingline2: 'Care Together',
    }
];

const ACCBanner = () => {

    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % bannerData.length);
        }, 6000);

        return () => clearInterval(interval);
    }, []);

    const currentBanner = bannerData[currentBannerIndex];

    const handleGetStarted = () => {
        navigate("/getstarted")
    }

    return (
        <div className='banner-section'>
            <div className='banner-img'>
                <img src={currentBanner.image} alt='Bannerimage' />

                <div className='banner-content'>

                    <div className='content-head'>
                        {currentBanner.headingline1.split('&').map((heading, index) => (
                            <div key={index}>{heading.trim()}</div>
                        ))}
                    </div>

                    <div className='content-head'>
                        {currentBanner.headingline2.split('&').map((heading, index) => (
                            <div key={index}>{heading.trim()}</div>
                        ))}
                    </div>

                    <div className='banner-button'>
                        <Button className="p-button-sm" onClick={handleGetStarted}>Schedule Call</Button>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default ACCBanner