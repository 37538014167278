import React from "react";
import "./CommitmentSection.scss";
import dotsImage from "../../../Assets/Parentspage/Dots.svg";
import mainImage from "../../../Assets/Parentspage/Our_Commitment_to_excellent.png";

const CommitmentSection = () => {
  return (
    <div className="commitment-section">
      <div className="images-and-content">
        <div className="images-container">
          <div>
            <img src={dotsImage} alt="Decorative dots" className="dots-image" />
          </div>
          <div>
            <img src={mainImage} alt="Therapy session" className="main-image" />
          </div>
        </div>
        <div className="text-content">
          <h2>Our Commitment to Excellence</h2>
          <p>
            At Autism Complete Care, excellence means more than just
            high-quality therapy. It means a commitment to making our clinic a
            place where children can find joy in learning and families feel
            supported and valued.
          </p>
        </div>
      </div>
      <div className="points-container">
        <ul>
          <div className="point-one">
            <li>
              <div>
                <b>Tailored Therapy Techniques</b> <br />
                Utilizing the latest research and methodologies in ABA therapy
                to foster effective learning and positive behavior change.
              </div>
            </li>
          </div>

          <div className="point-two">
            <li>
              <div>
                <b>Collaborative Care</b>
                <br />
                Working with parents, caregivers, and other professionals to
                support each child’s unique needs.
              </div>
            </li>
          </div>

          <div className="point-three">
            <li>
              <div>
                <b>Ongoing Training and Development</b> <br />
                Our team continuously advances their skills and knowledge to
                provide the best possible care.
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CommitmentSection;
