import './App.scss'

import Navigation from './Navigation/Navigation';
function App() {
    return (
        <>
        
        <Navigation/>

        </>
    );
}

export default App;
