import React from 'react';
import { Button } from 'primereact/button';
import './Ourcommunity.scss';

const OurCommunity = () => {
  return (
    <div className="join-community-container">
      <div className="join-community-content">
        <h2>Join Our Community</h2> {/* Updated header text */}
        <p>We invite you to learn more about our approach, our team, and how we can support your family on your journey. At Autism Complete Care, we are more than a clinic; we are a community united in our dedication to making a difference in the lives of children with autism.</p>
        <Button label="Join Now" className="p-button-rounded join-now-button" />
      </div>
    </div>
  );
};

export default OurCommunity;
