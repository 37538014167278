import React from 'react';
import './ABAServices.scss';
import Header from '../Home/Header/Header';
import Early_Intervention_PlanImg from '../../Assets/ABA-Services/Early_Intervention_Plan.png';
import Footer from '../Home/Footer/Footer';
import { Button } from 'primereact/button';
import { FaChevronLeft } from 'react-icons/fa';

const Early_Intervention_Plan = () => {
    return (
        <>
            <Header />

            <div className='p-4 aba-section' style={{ marginTop: "120px" }}>

                <Button className='back-button'><a href="/abaServices"> <FaChevronLeft /> Back</a></Button>

                <div className="aba-early-section w-full">
                    <div className=' p-0'>
                        <h2>Early Intervention Plan</h2>

                        <div className='abc-objective'>
                            <h3>Objective:</h3>
                            <p>
                                To capitalize on the early development stages,
                                targeting children aged <b>2-6 years</b>, with a focus on foundational skills such as communication,
                                social interaction, and basic learning skills.
                            </p>
                        </div>

                    </div>

                    <div className='early-plan-image p-0'>
                        <img src={Early_Intervention_PlanImg} alt="" />
                    </div>
                </div>

                <div className="acc-key-points">

                    <h3>Key Components:</h3>

                    <ul>
                        <div className='point-one'>
                            <li >
                                <div>
                                    <b className='point-one' >Initial Assessment:</b> Use standardized tools like the VB-MAPP (Verbal Behavior Milestones Assessment and Placement Program) to establish baseline levels and identify areas of need.
                                </div>
                            </li>
                        </div>

                        <div className='point-two'>
                            <li>
                                <div>
                                    <b>Intensive Therapy:</b> Implement a 25-40 hours per week therapy schedule, emphasizing one-on-one sessions to foster skill acquisition in a structured environment.
                                </div>
                            </li>
                        </div>

                        <div className='point-three'>
                            <li>
                                <div>
                                    <b>Parent Training:</b> Engage parents in weekly training sessions to reinforce skills at home, emphasizing strategies to support language development and social engagement.
                                </div>
                            </li>
                        </div>

                        <div className='point-four'>
                            <li>
                                <div>
                                    <b>Interdisciplinary Collaboration:</b> Involve psychiatric professionals to address any co-occurring conditions early on and to tailor interventions that support overall development.
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Early_Intervention_Plan